import header from './header.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={header} className="App-image" alt="header" />
        <p>
         PROFESSIONAL WAITERS COMING TO A PARTY NEAR YOU SOON 
        </p> 
      </header>
    </div>
  );
}

export default App;
